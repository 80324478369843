<template>
  <div :class="$style.root">
    <CoreImageComponent
      :image="image?.attributes"
      :showCaption="false"
      :data-image-zoom-allowed="canZoom(image?.attributes) ? '' : null"
      :data-upload-id="image?.id"
    />
    <button
      v-if="canZoom(image?.attributes)"
      :class="$style.button"
      @click="setQueryParams"
    >
      <SvgoPlus :class="$style.svg" />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  image: {
    type: Object,
    required: true
  }
})

const canZoom = (image) => {
  return imageSizeCheck(image)
}

const route = useRoute()
const router = useRouter()
const setQueryParams = () => {
  router.push({ query: { ...route?.query, 'lightbox-image-id': props?.image?.id } })
}
</script>

<style module lang="scss">
.root {
  position: relative;
  --base--image--max-aspect-ratio: 1.5;
}

.root [data-orientation="landscape"] {
  --base--image--object-fit: contain;
}

.root [data-orientation="square"] {
  --base--image--object-fit: contain;
}

.root [data-orientation="portrait"] {
  --base--image--spect-ratio: var(--core-image-carousel--aspect-ratio, 9 / 6);
  --base--image--object-fit: contain;
}

.root:hover .button {
  pointer-events: all;
  opacity: 1;
}

.button {
  composes: reset-button from global;
  position: absolute;
  z-index: 100;

  top: calc(var(--unit--default) / 2);
  right: calc(var(--unit--default) / 2);

  width: var(--size--button);
  height: var(--size--button);

  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.svg {
  overflow: visible;
}

.svg * {
  stroke: var(--color--magenta);
  stroke-width: var(--stroke--default);
  stroke-linecap: round;
}
</style>
