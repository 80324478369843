<template>
  <div v-if="images" :class="$style.root" :data-carousel="images?.length > 1 ? '' : null">
    <BaseCarouselComponent
      :class="$style.slides"
      type="fade"
      :pauseOnHover="true"
      :rewind="true"
      @setSlideIndex="slideIndex = $event"
    >
      <template #top="{ goToSlide }">
        <client-only v-if="images?.length > 1">
          <nav :class="$style.nav">
            <BaseCarouselGoComponent :class="$style.button" :go-to-slide="goToSlide" index="<">
              <CoreButtonsArrowComponent />
            </BaseCarouselGoComponent>
            <BaseCarouselGoComponent :class="$style.button" :go-to-slide="goToSlide" index=">">
              <CoreButtonsArrowComponent dir='next' />
            </BaseCarouselGoComponent>
          </nav>
        </client-only>
      </template>

      <BaseCarouselSlideComponent
        v-for="image in images"
        :key="image?.id"
        :class="$style.slide"
      >
        <CoreBlocksImagesImageComponent :image="image" />
      </BaseCarouselSlideComponent>
    </BaseCarouselComponent>
    <div
      :class="$style.footer"
      v-if="images?.length > 1"
    >
      <div v-if="images?.length > 1">
        {{ slideIndex+1 }}&hairsp;/&hairsp;{{ images?.length }}
      </div>
    </div>
    <div
      v-if="captionForCurrentSlide"
      :class="$style.caption"
    >
      {{ captionForCurrentSlide }}
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  images: array
}>()

const slideIndex = ref(0)
const captionForCurrentSlide = computed(() => {
  return props?.images[slideIndex.value ]?.attributes?.caption
})
</script>

<style module>
.root {
  --base--image--max-aspect-ratio: 1.5;

  position: relative;
}

.nav {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(var(--unit--default) / 2);
  padding-right: calc(var(--unit--default) / 2);

  pointer-events: none;
}

.nav * {
  pointer-events: auto;
}

.button {
  composes: reset-button from global;
}

.footer {
  text-align: center;
  margin-top: calc(var(--unit--default) / 4);
}

.root:not([data-carousel]) .caption {
  margin-top: calc(var(--unit--default) / 2);
}

.caption {
  text-align: center;
}
</style>
