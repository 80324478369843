<template>
  <div
    v-if="files"
    :class="$style.root"
  >
    <div :class="$style.label">
      {{ $t('blocks.files.label') }}
    </div>

    <ul :class="$style.list">
      <li
        v-for="file in files"
        :key="file.id"
        :class="$style.item"
      >
        <div>
          <a
            :href="fileUrl(file?.attributes?.url)"
            target="_blank"
            rel="noopener noreferrer"
            :class="$style.link"
          >
            {{ file?.attributes?.caption || fileNameWithoutExt(file?.attributes?.name) }}
          </a>
        </div>
        {{ humanSize(file?.attributes?.size) }}, {{ file?.attributes?.mime?.split('/')?.pop()?.toUpperCase() }}
      </li>
    </ul>
  </div>

</template>

<script lang="ts" setup>
import prettyBytes from 'pretty-bytes'

const props = defineProps<{
  files: array
}>()

const fileUrl = (url: string) => baseStrapiGetAbsoluteFileUrl(url)
const humanSize = (size: number) => prettyBytes(size * 1000)
const fileNameWithoutExt = (name: string) => name?.replace(/\.[^/.]+$/, "")
</script>

<style module>
.root {
  position: relative;
}

.label {
  text-transform: uppercase;
  margin-bottom: var(--unit--default);
}

.list {
  composes: reset-list from global;
}

.item:not(:last-of-type) {
  border-bottom: solid var(--stroke--default);
  padding-bottom: .2em;
  margin-bottom: .2em;
}

.link {
  composes: reset-link link from global;
  text-transform: uppercase;
}
</style>
